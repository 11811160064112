<template>
	<div class="order-status">

		<div class="order-status__content">
			<div class="order-status__video">
				<video v-if="isMD" autoplay muted loop playsinline class="anim-fade-in">
					<source src="@/assets/home/intro/video-background.mp4" type="video/mp4">
				</video>
				<video v-else autoplay muted loop playsinline class="anim-fade-in">
					<source src="@/assets/home/intro/video-background-mobile.mp4" type="video/mp4">
				</video>
			</div>

			<div class="order-status__text">
				<v-container>
					<v-row>
						<v-col cols="12" class="text-center">

							<template v-if="order">
								<div class="mt-15">
									<svgicon name="logo" original/>
									<h1 class="mt-8 d-flex align-center justify-center"><span class="dot white"></span>{{order.ID}}<span
											class="dot white"></span></h1>
									<p>{{page.texts.ORDER_NUMBER.NAME}}</p>
								</div>

								<template v-if="isPayed">
									<h1 class="mt-8">{{page.texts.ORDER_THANK.NAME}}</h1>
									<p class="mt-4">{{page.texts.ORDER_WE_ARE_ALREADY.NAME}}</p>

									<btn-link :to="{ name: 'home' }" class="mx-auto mt-10" :label="labelHomeText"/>
								</template>
								<template v-else>
									<h1 class="mt-8">{{page.texts.ORDER_IS_NOT_PAID.NAME}}<!--Your order is not payed-->...</h1>
									<p class="mt-4">{{page.texts.ORDER_IT_SEEMS.NAME}}<!--It seems the payment has not succeeded. Please try again.--></p>

									<btn-link :to="{ name: 'order', params:{id: order.ID} }"
											  class="mx-auto mt-10"
											  :label="labelRetryText"/>
								</template>
							</template>
							<template v-else>
								<div class="mt-15">
									<svgicon name="logo" original/>
								</div>

								<h1 class="mt-8">Order not found...</h1>
								<p class="mt-4">We could not find the specified order. If you feel this is an error - please contact us.</p>

								<btn-link :to="{ name: 'home-contacts' }" class="mx-auto mt-10" label="Contact us"/>
							</template>

						</v-col>
					</v-row>
				</v-container>
			</div>
		</div>

	</div>
</template>

<script>
	import BtnLink from '@/components/btn-link';
	import vp from "@/mixins/vp";

	export default {
		name: "order-status",
		mixins: [vp],
		components: {
			BtnLink
		},
		data() {
			return {}
		},
		computed: {
			order() {
				return this.$store.state.page?.OrderStatusController?.order;
			},
			isPayed() {
				return this.order.PAYED === "Y";
			},
            page() {
                return this.$store.state.page.OrderStatusController;
            },
            labelRetryText() {
                return this.page.texts?.ORDER_RETRY?.NAME;
            },
            labelHomeText() {
                return this.page.texts?.ORDER_HOME_PAGE?.NAME;
            },
		}
	}
</script>

<style lang="scss">
	.order-status {

		&__content {
			position: relative;
			height: 640px;
			display: flex;
			align-items: center;
			justify-content: center;
			background: $black;
		}

		&__video {
			//width: 320px;
			//margin: 0 auto;
			position: absolute;
			width: 100%;
			height: 100%;
			opacity: 0.4;

			video {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
				//mix-blend-mode: lighten;
				//animation: fade-in 2s forwards;
			}
		}

		&__text {
			position: relative;
			z-index: 1;

			p, span {
				color: $white;
			}

			svg {
				width: 128px;
				height: 128px;
			}

			.btn-link {
				color: $white !important;
			}
		}
	}
</style>
